<template>
	<div class="navhead flexab">
		<img :src="utils.loadImg('logo2.png')" class="point" alt="" @click="clickToIndex" height="26" />
		<div class="rig flex flexa">
			<el-tooltip v-model:visible="visible" trigger="click" :trigger-keys="['Enter']" :show-arrow="false" effect="light" popper-class="m-toolpop">
				<template #content>
					<div class="m-so_toolpop">
						<div class="hisbox">
							<div class="hisitem cud">搜索历史</div>
							<div class="hisitem" v-for="(item, index) in searchList" :key="index" @click="toSearch(item)">
								{{ item }}
							</div>
							<div v-if="$store.getters['searchStore/get_search'].length" class="hisitem" style="color: #337eff" @click="$store.commit('searchStore/clearSearch')">
								清除历史
							</div>
						</div>
						<div class="resbox" v-loading="getPending">
							<div class="item">
								<div class="res-titb1">
									<div class="h3">知识库文档（{{ getTotal.doc > 99 ? '99+' : getTotal.doc }}）</div>
									<div class="more" @click="$router.push({
										path: '/knowledge/search',
										state: {
											search_tab: 1,
											search
										}
									});blurSearch()">查看更多 &gt;</div>
								</div>
								<ul class="ul-listl2">
									<li v-for="(item, index) in getDoc" :key="index">
										<a href="JavaScript:void(0)" class="con" @click="$router.push({
											name: `knowledgeInfo`,
											params: {
												id: item.id
											}
										});blurSearch()">
											<div class="rr">所属分类：{{ item.categoryName }}</div>
											<div class="tit" v-html="item.docTitle"></div>
										</a>
									</li>
								</ul>
							</div>
							<!-- <div class="item">
								<div class="res-titb1">
									<div class="h3">服务请求（{{ getTotal.request > 99 ? '99+' : getTotal.request }}）</div>
									<div class="more" @click="$router.push({
										path: '/knowledge/search',
										state: {
											search_tab: 2,
											search,
										}
									});blurSearch()">查看更多 &gt;</div>
								</div>
								<ul class="ul-listl2">
									<li v-for="(item, index) in getRequest" :key="index">
										<a href="JavaScript:void(0)" class="con" @click="$router.push({
											name: `serviceInfo`,
											params: {
												id: item.id
											}
										});blurSearch()">
											<div class="rr"> 工单编号：{{ item.outTradeNo }}</div>
											<div class="tit" v-html="item.problemProfile"></div>
										</a>
									</li>

								</ul>
							</div> -->
							<div class="item">
								<div class="res-titb1">
									<div class="h3">海量资讯（{{ getTotal.announ > 99 ? '99+' : getTotal.announ }}）</div>
									<div class="more" @click="$router.push({
										path: '/knowledge/search',
										state: {
											search_tab: 3,
											search
										}
									});blurSearch()">查看更多 &gt;</div>
								</div>
								<ul class="ul-listl2">
									<li v-for="(item, index) in getAnnoun" :key="index">
										<a href="JavaScript:void(0)" class="con" @click="$router.push({
											name: 'newsDetail',
											params: {
												id: item.id
											}
										});blurSearch()">
											<div class="rr">{{ item.createTime }}</div>
											<div class="tit" v-html="item.title"></div>
										</a>
									</li>

								</ul>
							</div>
						</div>
						<div class="res-bot">
							<span class="all" @click="$router.push({
								path: '/knowledge/search',
								state: {
									search
								}
							});blurSearch()">查看全部结果 &gt;</span>
						</div>
					</div>
				</template>
				<div class="h-so" :class="visible ? 'active' : ''">
					<el-icon color="#A9A9A9" size="18" class="no-inherit">
						<Search />
					</el-icon>

					<el-input v-model="search" ref="search" @click="headInputFocus" @keydown.enter="toSearch(search)"
						@input="$store.dispatch('navSearch/multiActionLine', $event)" clearable
						placeholder="搜索服务请求/文档/资讯…" />
				</div>
			</el-tooltip>

			<el-tooltip placement="bottom" effect="light">
				<template #content>
					<div class="tc col4c font14 padd10">
						<img :src="utils.loadImg('ma2.png')" alt="" width="114" height="114" />
						<p class="padt10">微信扫一扫</p>
						<p class="padt4">及时查看工单进度</p>
					</div>
				</template>
				<div class="navmsg qr">
				</div>
			</el-tooltip>
			<el-tooltip placement="bottom" effect="light">
				<template #content>
					<ul class="headul1">
						<li @click="$router.push('/account')">
							<img :src="utils.loadImg('user.png')" alt="" width="14" />
							&nbsp;&nbsp; 收藏的文档
						</li>
						<li @click="$router.push({ path: '/account' })">
							<img :src="utils.loadImg('guan.png')" alt="" width="14" />
							&nbsp;&nbsp; 管理收藏夹
						</li>
					</ul>
				</template>
				<div class="navmsg star">
				</div>
			</el-tooltip>
			<div class="navmsg" @click="$router.push('/news')">
				<!-- :value="unread"  -->
				<el-badge :max="99" :hidden="unread == 0 ? true : false" class="item">
					<!-- <img :src="utils.loadImg('linf.png')" alt="" width="22" /> -->
					<div class="bell"></div>
				</el-badge>
			</div>

			<el-tooltip placement="bottom" effect="light">
				<template #content>
					<ul class="headul1">
						<li @click="$router.push('/account')">
							<img :src="utils.loadImg('user.png')" alt="" width="14" />
							&nbsp;&nbsp; 账号中心
						</li>
						<li @click="tuichu">
							<img :src="utils.loadImg('guan.png')" alt="" width="14" />
							&nbsp;&nbsp; 退出登录
						</li>
					</ul>
				</template>
				<div class="flex flexa col4c point padl30 navName">
					<img class="borahalf" :src="userInfo.headPic" alt="" width="24" />
					&nbsp;&nbsp; {{ userInfo.username }}
					<el-icon color="#666" size="12">
						<CaretBottom />
					</el-icon>
				</div>
			</el-tooltip>
		</div>
	</div>
	<div class="side navbar">
		<el-menu :collapse="isCollapse" class="el-menu-vertical" @select="current = $event" :default-active="computedCurrent"
			background-color="#fff" text-color="#333" :router="true" active-text-color="#fff">
			<template v-for="(item, index) in barList" :key="index">
				<el-sub-menu v-if="item.children && item.children.length > 0" :index="`${index}`" :route="item.path">
					<template #title>
						<img v-if="computedCurrent == item.path" :src="utils.loadImg(item.src1)" alt="" width="16"
							height="16" />
						<img v-else :src="utils.loadImg(item.src2)" alt="" width="16" height="16" />
						&nbsp;
						{{ item.tit }}
					</template>
					<el-menu-item-group>
						<el-menu-item :index="citem.path" :route="citem.path" v-for="(citem, cindex) in item.children"
							:key="cindex">
							<div class="child">{{ citem.tit }}</div>
						</el-menu-item>
					</el-menu-item-group>
				</el-sub-menu>
				<el-menu-item v-else :index="item.path" :route="item.path">
					<img v-if="computedCurrent == item.path" :src="utils.loadImg(item.src1)" alt="" width="16" height="16" />
					<img v-else :src="utils.loadImg(item.src2)" alt="" width="16" height="16" />
					&nbsp;
					<template #title>
						{{ item.tit }}
						<!-- <img v-if="item.tit=='在线支持'" :src="utils.loadImg(item.src3)" alt="" width="10" height="10" class="marl20" /> -->
					</template>
				</el-menu-item>
			</template>
		</el-menu>
		<div @click="isCollapse = !isCollapse" class="flex flexa open">
			<img v-if="isCollapse" :src="utils.loadImg('cols2.png')" alt="" width="16" height="16" />
			<img v-if="!isCollapse" :src="utils.loadImg('cols1.png')" alt="" width="16" height="16" />
			&nbsp;
		</div>
	</div>
	<!-- <div v-if="myroute.meta.titL" :class="['navmb', { navpl: isCollapse }]">
    <div
      class="flex flexa"
      v-for="(item, index) in myroute.meta.titL"
      :key="index"
    >
      <span v-if="index > 0">&nbsp; >&nbsp; </span>
      <span
        :class="
          index + 1 == myroute.meta.titL.length && index != 0 ? 'colzhuti' : ''
        "
        >{{ item }}</span
      >
    </div>
  </div> -->
	<div :class="['navmin', { navpl: isCollapse }]">
		<p v-if="myroute.meta.titL" class="h60"></p>
		<router-view v-slot="{ Component }">
			<keep-alive include="">
				<component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name" />
			</keep-alive>
			<component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.name" />
		</router-view>
	</div>

	<el-popover placement="left" :width="300" :visible="sdboxVis">
		<template #reference>
			<div class="r-sdbox" :class="hideRs ? 'hide' : ''">
				<div class="r-cls" @click="hideRs = true">
					<el-icon>
						<CircleCloseFilled />
					</el-icon>
				</div>
				<div class="btntip btnzhuti font14" @click.stop="sdboxVis = !sdboxVis">

					<img :src="utils.loadImg('erji.png')" alt="" width="20" height="20" />
					&nbsp; 支持
				</div>
			</div>

		</template>
		<ul class="nowrap padt14 padb14 padl20">
			<li class="flex flexa">
				<img :src="utils.loadImg('f1.png')" alt="" />
				<div class="padl10">
					<p class="col24 font14">电话咨询</p>
					<p class="col4c font12 fontw4">4000123005</p>
				</div>
			</li>
			<li class="flex flexa padt24">
				<img :src="utils.loadImg('f2.png')" alt="" />
				<div class="padl10">
					<p class="col24 font14">在线客服</p>
					<p class="col4c font12 fontw4">专业工程师，为您快速解决数据库问题</p>
				</div>
			</li>
			<li class="flex flexa padt24">
				<img :src="utils.loadImg('f3.png')" alt="" />
				<div class="padl10">
					<p class="col24 font14">提交工单</p>
					<p class="col4c font12 fontw4">
						产品问题、技术问题、常见问题快速响应
					</p>
				</div>
			</li>
			<li class="flex flexa padt24">
				<img :src="utils.loadImg('f4.png')" alt="" />
				<div class="padl10">
					<p class="col24 font14">聆听反馈</p>
					<p class="col4c font12 fontw4">
						您的建议我们会及时处理/反馈，非常感谢
					</p>
				</div>
			</li>
		</ul>
	</el-popover>
</template>
<script>

import { ElNotification } from 'element-plus'
//图标
import { Star, Search, Bell, CaretBottom, CircleCloseFilled } from '@element-plus/icons-vue'
import { accountBaseInfo } from "@/utils/api1.js"
import { mapGetters } from 'vuex'

export default {
	inject: ['routerRefresh'],
	components: {
		Star,
		Bell,
		Search, CaretBottom, CircleCloseFilled
	},
	computed: {
		...mapGetters({
			searchList: 'searchStore/get_search',
			getTotal: 'navSearch/getTotal',
			getDoc: 'navSearch/getDoc',
			getAnnoun: 'navSearch/getAnnoun',
			getRequest: 'navSearch/getRequest',
			getPending: 'navSearch/getPending'
		}),
		barList() {
			// 登录获取信息判断是否为COE，以此拼接到菜单
			// 路由部分，创建到路由判断是否COE账户，登录成功后build菜单，决定指定路由。
			// if (this.userInfo.isCoe == 1) {
			// 	console.log('添加路由')
			// 	this.$router.addRoute('root', {
			// 		path: "/document/manager", 
			// 		name: 'document/manager',
			// 		component: () => import('@/views/document/manager.vue'),
			// 		meta:{  tab:'/document/manager' },
			// 	})
			// } else {
			// 	this.$router.removeRoute('document/manager')
			// }
			let route_1 = this.userInfo.isCoe == 1 ? [{ tit: '公司文档管理', src2: 'icon4.png', src1: 'icon4-a.png', path: '/document/manager' }] : []
			let route_2 = this.userInfo.isSubBug == 1 ? [{ tit: 'Bug反馈', src2: 'icon5.png', src1: 'icon5-a.png', path: '/bug/list' }] : []
			
			return [
				{ tit: '控制台中心', src2: 'icon1.png', src1: 'icon1-a.png', path: '/controlBoard' },
				{
					tit: '服务请求', src2: 'icon2.png', src1: 'icon2-a.png', path: '/service', children: [
						{
							tit: '技术服务请求', path: '/tech/list/2'
						}, {
							tit: '非技术服务请求', path: '/tech/list/1'
						}]
				},
				{ tit: '知识库', src2: 'icon3.png', src1: 'icon3-a.png', path: '/knowledge/index' },
			].concat(route_1).concat(route_2).concat(
				[
					{ tit: '程序/更新', src2: 'icon6.png', src1: 'icon6-a.png', path: '/patch' },
					{ tit: '认证', src2: 'icon7.png', src1: 'icon7-a.png', path: '/authenticationCenter' },
					{ tit: '账号中心', src2: 'icon8.png', src1: 'icon8-a.png', path: '/account' },
				]
			)
		},
		visible: {
			get() {
				return this.visRoot
			},
			set(v) {
				if (this.visControl) {
					this.visControl = false
					return
				}
				this.visRoot = v
			}
		},
		// 强制更新导航关键
		computedCurrent() {
			if (this.state.force) {
				return this.state.force
			}
			return this.current
		}
	},
	data() {
		return {
			state: history.state,
			visRoot: false,
			visControl: false,
			search: '',
			hideRs: true,
			sdboxVis: false,//右侧按钮弹框
			current: '/controlBoard',
			myroute: this.$route,
			isCollapse: false,
			screenWidth: document.body.clientWidth,
			userInfo: {},
			unread: 3,
		}
	},
	watch: {
		'$route'(val, old) {
			this.myroute = val;
			this.state = history.state
			this.getInfo()
			if (val.meta.tab && val.meta.tab != this.current) {
				this.current = val.meta.tab ?? val.fullPath;
			};
		},
		screenWidth(val) {
			this.screenWidth = val;
			// console.log(val, );
			if (val < 1100) {
				this.isCollapse = true
			} else {
				this.isCollapse = false

			}
		},


	},
	mounted() {
		this.myroute = this.$route;
		this.current = this.$route.meta.tab ?? this.$route.fullPath;
		const that = this;
		that.getInfo()
		window.onresize = () => {
			return (() => {
				// window.screenWidth = document.body.clientWidth
				// that.screenWidth = window.screenWidth
				that.screenWidth = document.body.clientWidth
			})()
		};
		document.addEventListener('click', function (e) {
			that.sdboxVis = false;
		});
	},
	methods: {
		clickToIndex(){
			const externalUrl = 'https://www.vastdata.com.cn';
      		window.location.href = externalUrl;
		},
		getInfo() {
			let that = this
			accountBaseInfo().then(res => {
				console.log('用户详情',res)
				that.userInfo = res
			})
		},
		toSearch(search) {
			if (this.$route.name == 'knowledge/search') {
				this.$store.commit('bigSearch/setSearch', search)
				return this.$store.dispatch('bigSearch/multiActionLine').then((o) => this.$store.commit('searchStore/putSearch', o))
			}
			this.$router.push({
				path: '/knowledge/search',
				state: {
					search
				}
			}).then(() => setTimeout(() => {
				this.blurSearch()
			}, 0))
		},
		blurSearch() {
			this.visible = false
			this.$refs.search.blur()
		},
		ElNotificationFun() {
			let html = `<div class="ElNot-box"><p>您的工单39239HL 已经有了新的回复</p><p class="btn"><a>查看详情</a></p></div>`;
			ElNotification({
				title: '工单待处理通知',
				dangerouslyUseHTMLString: true,
				message: html,
			})
		},
		headInputFocus(e) {
			// 如果已展开的情况下，再次点击，则失效
			if (this.visRoot) {
				return this.visControl = true
			}
			this.$store.dispatch('navSearch/multiActionLine', this.search)
		},
		tuichu() {
			localStorage.setItem('hltoken', '');
			this.$store.state.token = '';
			this.$router.push('/login');
			this.$store.commit('cache/clearCache')
		},
	}
}
</script>
<style lang="scss" scoped>
.headul1 {
	font-size: 14px;

	li {
		display: flex;
		height: 36px;
		color: #4c4c4c;
		padding: 0 10px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		cursor: pointer;
		width: 144px;
	}

	li:nth-child(1) {
		border-bottom: 1px solid #f1f1f1;
	}
}

.el-menu-vertical:not(.el-menu--collapse) {
	width: 240px;
}

.open {
	height: 56px;
	padding: 0 20px;
	cursor: pointer;
	width: 100%;
	bottom: 0;
	box-sizing: border-box;
	border-top: 1px solid #f1f1f1;
}

.open:hover {
	background-color: #f7f7f7;
}

.navmin {
	background: #f6f6f6;
	min-height: 100vh;
	box-sizing: border-box;
	padding-top: 60px;
	padding-right: 20px;
	padding-left: 260px;
	padding-bottom: 20px;
	transition: padding-left 0.5s;
	overflow: hidden;
}

.navpl {
	padding-left: 83px !important;
}

.navhead {
	background: #fff;
	height: 60px;
	padding: 0 25px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 22;
	box-sizing: border-box;
	border-bottom: 1px solid #f3f3f3;
}

.el-menu-vertical {
	height: calc(100vh - 56px - 60px);
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none
	}
}
.side {
	--el-menu-item-font-size: 14px;
	padding-top: 60px;
	background: #fff;
	position: fixed;
	box-sizing: border-box;
	height: 100vh;
	overflow-y: hidden;
	padding-bottom: 56px;
	left: 0;
	z-index: 10;
	top: 0;
	border-right: solid 1px #f3f3f3;

	.el-menu-item:hover {
		background-color: #f7f7f7 !important;
	}

	.el-menu-item.is-active {
		background: #ff6a00 !important;
	}
}

.r-sdbox {
	position: fixed;
	right: 10px;
	bottom: 26%;
	z-index: 100;

	&.hide {
		transform: translateX(70px);
		transition: .4s;
	}
}

.btntip {
	color: #fff;
	writing-mode: vertical-lr;
	display: flex;
	align-items: center;
	width: 42px;
	padding: 15px 0;
	box-shadow: 0px 2px 8px 0px #ffc49a;

}

.navmsg {
	height: 60px;
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 18px;
	cursor: pointer;
	background-size: 18px 18px;
	background-position: center;
	background-repeat: no-repeat;
}

.navmsg:hover {
	background-color: #f7f7f7;
	background-size: 18px 18px;
	background-position: center;
	background-repeat: no-repeat;
	.bell{
		width: 18px;
		height: 18px;
		background-size: 18px 18px;
		background-repeat: no-repeat;
		background-image: url(../assets/imgs/bell2.png);
	}
}

.inline-input {
	width: 232px;
	margin-right: 30px;
}


.m-so_toolpop {
	width: 1010px;

	.hisbox {
		border-bottom: 1px solid #e8e9eb;
		padding: 35px;

		.hisitem {
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
			color: #4c4c4c;
			cursor: pointer;
			margin-right: 40px;
			margin-bottom: 10px;
			transition: 0.4s;

			&.cud {
				cursor: default;
			}

			&:hover {
				color: #ff6a00;
			}
		}
	}

	.resbox {
		padding: 0 35px;
	}
}

.res-titb1 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0;
	border-bottom: 1px solid #e8e9eb;
	margin-bottom: 20px;

	.h3 {
		font-size: 14px;
		color: #4c4c4c;
	}

	.more {
		font-size: 14px;
		font-weight: 500;
		color: #ff6a00;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}

.resbox {
	max-height: 65vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.res-bot {
	text-align: right;
	border-top: 1px solid #e8e9eb;
	padding: 14px 24px;

	.all {
		cursor: pointer;
		color: #ff6a00;

		&:hover {
			text-decoration: underline;
		}
	}
}

.r-cls {
	cursor: pointer;
	position: absolute;
	top: -2px;
	right: -2px;
	font-size: 16px;
	opacity: .7;
	color: #333;
}
.qr {
	background-image: url(../assets/imgs/qr1.png);
	&:hover {
		background-image: url(../assets/imgs/qr2.png);
	}
}
.star {
	background-image: url(../assets/imgs/star1.png);
	&:hover {
		background-image: url(../assets/imgs/star2.png);
	}
}
.bell {
	width: 18px;
	height: 18px;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	background-image: url(../assets/imgs/bell1.png);
}
@media (min-width: 500px){
	.h-so{
		//background-color: red;
		height: 40px;
	}
	.navmsg{
		height: 40px;
		margin-left: 0;
	}
	.navName{font-size: 10px}
}
@media (min-width: 1000px){
	.h-so{
		//background-color: green;
		height: 60px;
	}
	.navmsg{
		height: 60px;
		margin-left: 18px;
	}
	.navName{font-size: 14px}
}
@media (min-width: 2000px){
	//.h-so{background-color: blue}
}
</style>